<template>
<div class="tab-pane py-2 py-xl-2 fade show active" id="wizard1" role="tabpanel" aria-labelledby="wizard1-tab">
    <div class="row justify-content-center">
        <div class="col-xxl-6 col-xl-8">
            <div class="mb-3">
                <label class="small mb-1" for="inputUsername">Usuario</label>
                <input class="form-control" id="inputUsername" type="text"  :value="this.$store.state.login.user" disabled/>
            </div>
            <div class="row gx-3">
                <div class="mb-3 col-md-6">
                    <label class="small mb-1" for="inputFirstName">Nombre</label>
                    <input class="form-control disabled" id="inputFirstName" type="text" :value="this.$store.state.user.user.nombre" disabled/>
                </div>
                <div class="mb-3 col-md-6">
                    <label class="small mb-1" for="inputLastName">Apellido</label>
                    <input class="form-control disabled" id="inputLastName" type="text"  :value="this.$store.state.user.user.apellido" disabled/>
                </div>
            </div>
            <div class="row gx-3">
                <div class="mb-3 col-md-12">
                    <label class="small mb-1" for="inputEmailAddress">Email</label>
                    <input class="form-control disabled" id="inputEmailAddress" type="email"  :value="this.$store.state.login.user" disabled/>
                </div>
                 <div class="mb-3 col-md-12">
                    <label class="small mb-1" for="inputEmailAddress">Tipo de proveedor</label>
                    <input class="form-control disabled" id="inputEmailAddress" type="email"  :value="getTipoProveedor(this.$store.state.user.user.empresa.tipoProveedor.codigo)" disabled/>
                </div>
            </div>

            <div class="row gx-3">
                 <div class="mb-3 col-md-12">
                    <label class="small mb-1" for="inputRazonSocial">Raz&oacute;n Social</label>
                    <input class="form-control disabled" id="inputRazonSocial" type="text"  :value="this.$store.state.user.user.empresa.razon_social" disabled/>
                </div>
            </div>
            <div class="row gx-3">
                <div class="mb-3 col-md-6">
                    <label class="small mb-1" for="inputCuit">CUIT</label>
                    <input class="form-control disabled" id="inputCuit" type="text" :value="this.$store.state.user.user.empresa.cuit" disabled/>
                </div>
            </div>
   
        </div>
    </div>
</div>
</template>

<style>
.title {
    color: black !important;
    cursor: auto;
}
</style>

<script>
import router from '@/router/index.js' // Vue router instance
import UtilJS from '../../../../assets/js/util'
export default {
    mixins: [UtilJS],
    components: {},
    mounted: function () {},
    methods: {},
    computed: {},
    props: {}
}
</script>>
