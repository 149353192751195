<template>
<div>
    <!-- navbar -->
    <Navbar/>
    <div id="layoutSidenav">
        <Menu />
        <div id="layoutSidenav_content">
            <main>
            <!-- header -->
                 <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div class="container-xl px-4">
                            <div class="page-header-content pt-4">
                                <div class="row align-items-center justify-content-between">
                                    <div class="col-auto mt-4">
                                        <h1 class="page-header-title">
                                            <div class="page-header-icon"><i class="fas fa-user-edit"></i></div>
                                            Autogesti&oacute;n
                                        </h1>
                                        <div class="page-header-subtitle">Informaci&oacute;n del usuario/a</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
            <!-- fin header -->
            <!-- body -->
                <div class="container-xl px-4 mt-n10">
                        <!-- Wizard card navigation-->
                        <div class="card">
                            <div class="card-header border-bottom">
                                <!-- Wizard navigation-->
                                <div class="nav nav-pills nav-justified flex-column flex-xl-row nav-wizard" id="cardTab" role="tablist">
                                    <!-- Wizard navigation item 1-->
                                    <CardButton title="Información" active="active" subTitle="Información del usuario" id="wizard1" numberCard="1"/> 
                                    <!-- Wizard navigation item 2-->
                                    <CardButton title="Contraseña" subTitle="Editar Contraseña" id="wizard2" numberCard="2"/> 
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="tab-content" id="cardTabContent">
                                    <!-- Wizard item 1-->
                                    <Informacion/>
                                    <!-- Wizard item 2-->
                                    <Password/>
                                </div>
                            </div>
                        </div>
                    </div>
            <!-- fin body -->
            </main>
        </div>
    </div>

</div>
</template>

<style>

</style>

<script>
import router from '@/router/index.js' // Vue router instance
import spinner from '../../components/spinner'
import Vue from 'vue'
import Navbar from '../../components/home/navbar/navbar'
import Menu from '../../components/home/menu'
import CardButton from '../../components/home/step/util/cardButton'
import Informacion from '../../components/home/step/perfil/informacion'
import Password from '../../components/home/step/perfil/password'
export default {
    name: "Home",
    components: {
        spinner,
        Navbar,
        Menu,
        CardButton,
        Informacion,
        Password
    },
    mounted: function () {
        this.addMenu();
    },
    methods: {
        addMenu(){
            document.body.classList.remove("sidenav-toggled");
        }
    },
    computed: {
    },
};
</script>
