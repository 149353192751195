<template>
  <div class="tab-pane py-2 py-xl-2 fade show" id="wizard2" role="tabpanel" aria-labelledby="wizard2   -tab">
    <Spinner/>
    <div class="row justify-content-center">
      <div class="col-xxl-6 col-xl-8">
        <div class="mb-3">
          <label class="small mb-1" for="passActual">Contrase&ntilde;a Actual</label>
          <input class="form-control background-input-disabled" id="passActual" v-model="passActual" type="password" placeholder="Contraseña Actual"/>
        </div>
        <div class="row gx-3">
          <div class="mb-3 col-md-6">
            <label class="small mb-1" for="passNueva1">Nueva Contrase&ntilde;a</label>
            <input class="form-control background-input-disabled" id="passNueva1" v-model="passNueva1" type="password" placeholder="Nueva contraseña"/>
          </div>
          <div class="mb-3 col-md-6">
            <label class="small mb-1" for="passNueva2">Confirmar contrase&ntilde;a</label>
            <input class="form-control background-input-disabled" id="passNueva2" v-model="passNueva2" type="password" placeholder="Confirmar contraseña"/>
          </div>
        </div>
        <div class="row gx-3">
          <div class="col-md-12 text-end mt-4 mb-md-0">
            <a class="btn btn-primary color-btn" @click="cambiarPass()">Cambiar Contrase&ntilde;a</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.title {
  color: black !important;
  cursor: auto;
}

.background-input-disabled {
  background-color: white !important;
}
</style>

<script>
import router from '@/router/index.js'
import Vue from "vue"; // Vue router instance
import Spinner from "../../../spinner"
export default {
  components: {Spinner},
  mounted: function () {
        this.$store.commit("SET_BLOQUEADO", false);

  },
  methods: {
    cambiarPass: function () {

      let actual = document.getElementById('passActual').value;
      let nueva1 = document.getElementById('passNueva1').value;
      let nueva2 = document.getElementById('passNueva2').value;

      if (!(actual == "" || nueva1 == "" || nueva2 == "")) {
        if (!(nueva1 != nueva2)) {
          this.$store.dispatch("login/verificarPassword")
        } else {
          Vue.swal("Portal de Proveedoras/es", "Las nuevas contraseñas no son iguales.", "info");
        }
      } else {
        Vue.swal("Portal de Proveedoras/es", "Debe ingresar todos los datos para continuar.", "info");
      }


    }
  },
  computed: {
    passActual: {
      get() {
      },
      set(value) {
        this.$store.commit("login/SET_PASS_ACTUAL", value);
      },
    },
    passNueva1: {
      get() {
      },
      set(value) {
        this.$store.commit("login/SET_PASS_NUEVA_1", value);
      },
    },
    passNueva2: {
      get() {
      },
      set(value) {
        this.$store.commit("login/SET_PASS_NUEVA_2", value);
      },
    },
  },
  props: {}
}
</script>>
